import "../styles/index.scss";

// LIBS imports
import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "custom-event-polyfill";

import { getBrowserNameAndVersion, isIOS, isIOSVersion11OrLess, isSmallDevice } from "./utils/responsive.utils";
import { Modal } from "./lib/modal";
// LIBS imports

export class Global {
  constructor() {
    this.init();
  }

  onLoad() {
    setTimeout(() => {
      document.body.classList.add('show');
    }, 1000);
  }

  iOSSetup() {
    if (isIOS) {
      document.body.classList.add('is-ios');
    }

    if (isIOSVersion11OrLess) {
      const { name, version } = getBrowserNameAndVersion();
      document.body.classList.add(`${name}-${version}`);
      document.body.classList.add(`isIOSVersion11OrLess`);
    }

    if (isSmallDevice) {
      document.body.classList.add(`isSmallDevice`);
    }
  }

  loadModal() {
    const modal = new Modal('.modal');
    modal.onLoad();
  }

  init() {
    console.info("[global] init");
    this.iOSSetup();
    this.onLoad();
    this.loadModal();
  }
}

new Global();
