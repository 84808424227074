import { getURLParameter } from "../utils/browser.utils";
import { getCookie, setCookie } from "../utils/cookies.utils";

export class Modal {
  modalName = 'modal-aviso-trabalho';

  constructor(id) {
    this.setEvents(id);
  }

  setEvents(selectorId) {
    if (!selectorId) {
      throw Error('Invalid HTML Selector');
    }

    this.modal = document.querySelector(selectorId);
    if (!this.modal) {
      throw Error('Invalid HTML Selector');
    }

    const closeButton = this.modal.querySelector('.close-button');

    const windowOnClick = (event) => {
      if (event.target === this.modal) {
        this.hide();
      }
    };

    closeButton.addEventListener('click', this.hide);
    window.addEventListener('click', windowOnClick);
  }

  onLoad = () => {
    const hasTestBParameters = getURLParameter('modal-test');

    if (
      hasTestBParameters
      && hasTestBParameters.toLowerCase() === 'b'
    ) {
      document.body.classList.add('modal-b');
    }

    const hasModalCookie = getCookie(this.modalName);
    if (!hasModalCookie || hasModalCookie === '0') {
      // this.show();
    }
  }

  show = () => {
    this.modal.classList.add('hide-modal');
  }

  hide = () => {
    this.modal.classList.remove('hide-modal');
    setCookie(this.modalName, 1, 5);
  }
}