import { addDaysToDate } from "./date.utils";

export function setCookie(cookieName, cookieValue, days = 0) {
  var today = new Date();
  var expire = new Date();

  if (days) {
    expire.setTime(+addDaysToDate(days));
  } else {
    expire.setTime(today.getTime() + 3600000 * 24 * 14);
  }

  document.cookie = cookieName + "=" + encodeURI(cookieValue) + ";expires=" + expire.toGMTString();
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}